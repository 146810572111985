:root {
	--cursor-stroke: #5631e9;
	--cursor-fill: transparent;
	--cursor-stroke-width: 1px;

	--base-image-width: 450px;
	--base-image-height: 600px;

	--image-width: 450px;
	--image-height: 600px;

	--bg-color: #27172e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Open Sans', sans-serif;
	// overflow: hidden;
}

.cursor {
	display: none;
}

.page {
	display: flex;
	flex-direction: column;
  // width: min(90%, 1200px);
  margin: 0 auto;
  border: 1px solid #000;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	
}
/* header */

header {
  margin-bottom: 5rem;
	min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 2em 0em;
  z-index: 2;
  position: relative;
}

#logo {
	text-decoration: none;
}

.content h1 {
	line-height: 1;
  padding: 0.5em 0 ;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #047582;
  mix-blend-mode: color-burn;
  font-size: 14vw;
}

h1,h2 {
  font-family: 'Playfair Display', serif;
  font-weight: normal;
}

.content {
	display: flex;
  align-items: center;
	justify-content: center;
	flex-direction: column;

	img {
	max-width: 100%;
  display: block;
  // opacity: 0;
	}
}

.item{
  /* border: 1px solid #000; */
  align-self: center;
  justify-self: center;
  text-decoration: none;
  color: #333;
}
.item:nth-child(odd){
  justify-self: start;
}
.item:nth-child(even){
  justify-self: end;
}
.item img{
  width: 100%;
  display: block;
  opacity: 0;
}
.item__image{
  position: relative;
  margin-bottom: 0.6em;
}
.left ul {
	height: 100%;
	width: 5.5em;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	margin: 0 auto;
	padding: 2em 0em;
	position: absolute;
	background-color: blue;
}

.left ul li {
	// display: inline-block;
  // position: absolute;
  // left: 1.5em;
  // bottom: 0.4em;
	// color: white;
  // transform-origin: 0 100%;
  transform: rotate(-90deg);
  
  
	list-style: none;
	position: relative;
	text-decoration: none;
	margin-left: auto;
}

.left ul li:nth-child(1) {
	margin-left: 0%;
}

.left ul li a {
	text-decoration: none;
	text-transform: uppercase;
	color: white;
	font-size: 80%;
	cursor: pointer;
	// letter-spacing: 0.2rem;
}

.item__title{
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 0.4em;
}
.item_p{
  line-height: 1.4;
  font-size: 1em;
}
.item_h{
  width: 100%;
}
.item_v{
  width: 80%;
}


/* footer */
.footer {
	position: relative;
	bottom: 0;
	left: 0;
	width: 100%;
	border-top: 1px solid #ccc;
  padding: 1em;
  font-size: 1.5rem;
  display: flex;
	align-items: center;
	// justify-content: space-evenly;
  // margin-top: 8em;
  line-height: 1.5;
  padding: 1em 0 4em 0;
}
.footer a {
  color: #333;
} 

.copy {
	margin-left: 7em;
}

.social {
	margin-right: 3em;
	margin-left: auto;
}

// .footer p+p {
//   margin-left: auto;
//   // text-align: right;
// }

@media screen and (max-width: 600px) { 
  .grid{
      display: block;
  }
  .item_v,.item_h{
      width: auto;
      margin-bottom: 3em;
  }
  .item img{
      width: 100%;
      max-width: none;
  }
}

#container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}

.curtain {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: #949494;
  transform: translate(0,100%)
}

[data-barba-namespace] {
  position: absolute;
  top: 0;
}

.loading__wrapper {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;

	.loading__text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 2vmin;
		font-family: "Red Rose", sans-serif;
		color: #fff;

		&--inner {
			animation: blink 1s infinite alternate-reverse;
		}

		@keyframes blink {
			from {
				opacity: 1;
			}
			to {
				opacity: 0.6;
			}
		}
	}

	.ts-line { 
		overflow: hidden; 
		padding: 0 0 0.2em 0; 
		margin: 0 0 -0.1em; 
	}

	blockquote {
		display: block;
		font-size: 3.5em; 
		max-width: 10em;
		margin: auto;
		line-height: 1;
		color: #fff;
		user-select: none;
		opacity: 0;
	
		small { 
			margin-top: 0.2em; 
			text-align: right; 
			display: block; 
			font-size: 0.6em; 
		}
	}

	.text__wrapper {
		position: relative;
		font-size: 10vmin;
		font-family: "Red Rose", sans-serif;
		text-transform: uppercase;
		font-display: swap;
		color: #fff;

		.text__row {
			display: block;

			.text {
				display: block;
				user-select: none;
				opacity: 0;
			}

			&:nth-child(1) {
				clip-path: polygon(0% 75%, 100% 75%, 100% 100%, 0% 100%);
			}
			&:nth-child(2) {
				clip-path: polygon(0% 50%, 100% 50%, 100% 75.5%, 0% 75.5%);
			}
			&:nth-child(3) {
				clip-path: polygon(0% 25%, 100% 25%, 100% 50.5%, 0% 50.5%);
			}
			&:nth-child(4) {
				clip-path: polygon(0% 0%, 100% 0%, 100% 25.5%, 0% 25.5%);
			}
			&:nth-child(5) {
				clip-path: polygon(0% -25%, 100% -25%, 100% 0.5%, 0% 0.5%);
			}
			&:nth-child(6) {
				clip-path: polygon(0% -50%, 100% -50%, 100% -24.5%, 0% -24.5%);
			}
			&.text__row--sibling {
				position: absolute;
				top: 0;
				left: 0;
				user-select: none;
			}
		}
	}
	
	.bg__transition--slide {
		background: #0e0e0e;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}

@media (any-pointer: fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		mix-blend-mode: difference;
		z-index: 100;

		&--large .cursor__inner {
			fill: var(--cursor-fill);
			stroke: var(--cursor-stroke);
			stroke-width: var(--cursor-stroke-width);
			opacity: 0.7;
		}
		&--small .cursor__inner {
			fill: var(--cursor-stroke);
			stroke: var(--cursor-fill);
			opacity: 0.7;
		}

		&--close {
			fill: none;
			stroke: var(--cursor-stroke);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 32px;
			mix-blend-mode: difference;
		}
	}
}
